export const submitForm = ({ fullname, email, phone }: { fullname: string; email: string; phone: string }, url: string) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const data = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'fullname',
          value: fullname,
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
        {
          objectTypeId: '0-1',
          name: 'phone',
          value: phone,
        },
      ],
      context: {
        pageUri: window.location.hostname,
        pageName: 'PreSales',
      },
    };
    const finalData = JSON.stringify(data);
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(true);
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        reject(xhr.responseText);
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        reject(xhr.responseText);
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        reject(xhr.responseText);
      }
    };

    xhr.send(finalData);
  });

export const submitSqueezeForm = ({ fullname, email, url }: { fullname: string; email: string; url: string }) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const data = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'fullname',
          value: fullname,
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
      ],
      context: {
        pageUri: window.location.hostname,
        pageName: 'PreSales',
      },
    };
    const finalData = JSON.stringify(data);

    xhr.open('POST', url);

    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(true);
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        reject(xhr.responseText);
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        reject(xhr.responseText);
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        reject(xhr.responseText);
      }
    };

    xhr.send(finalData);
  });
