import { Box, chakra, Flex, useMediaQuery } from '@chakra-ui/react';
import { BackgroundSeven } from 'assets';
import { useNavBarDimensions, SqueezeFormData } from 'utils';
import { useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';
import { FormDescription, FormFields } from 'components';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: `rgba(0, 0, 0, 0.2) url(${BackgroundSeven})`,
    backgroundPosition: 'right, right',
    backgroundSize: { base: 'cover', md: 'cover' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
    margin: { md: '0 auto' },
    gap: { base: '20px', lg: '40px', xl: '68px' },
    maxWidth: { xl: '1254px' },
    flexDirection: { base: 'column', lg: 'row' },
    justifyContent: 'space-between',
  },
});

const LeftContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: { base: '20px', lg: 'unset' },
  },
});

type PreSaleRateFormProps = {
  data: SqueezeFormData;
  path: string;
};

const PreSaleRateForm = ({ data, path }: PreSaleRateFormProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { enterCount } = useInViewport(ref);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isMobileView] = useMediaQuery('(max-width: 767px)');
  const { margin } = useNavBarDimensions();

  const [fields, setFields] = useState({ email: data?.email ?? '', fullname: data?.fullname ?? '', telephone: '' });

  return (
    <OuterContainer
      opacity={enterCount >= 1 ? 1 : 0}
      ref={ref}
      paddingLeft={!isMobileView ? margin : '20px'}
      paddingRight={!isMobileView ? margin : '20px'}
      marginTop={{ base: path === '/grandopening' ? '63px' : '100px', md: 'unset' }}
    >
      <Container
        padding={{ base: '21px 0', md: '68px 0', xl: path === '/grandopening' ? '0' : '68px 0px' }}
        width={{ md: '80%', lg: 'unset' }}
      >
        <LeftContainer width={{ lg: '80%', xl: 'unset' }}>
          <FormDescription path={path} />
        </LeftContainer>
        {!isSubmitted && (
          <Box width={{ base: '100%', xl: '38%' }}>
            <FormFields path={path} setFields={setFields} setIsSubmitted={setIsSubmitted} fields={fields} />
          </Box>
        )}
      </Container>
    </OuterContainer>
  );
};

export default PreSaleRateForm;
