import { Box, chakra, Text, useMediaQuery } from '@chakra-ui/react';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '30px', xl: '38px' },
    fontWeight: '400',
    lineHeight: { base: '36px', xl: '45px' },
    color: primaryTheme.colors.brand.white,
    textTransform: 'capitalize',
    whiteSpace: 'pre-line',
    letterSpacing: '-1px',
    maxWidth: '500px',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontWeight: '500',
    lineHeight: '130%',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    backgroundClip: 'text',
  },
});

const HighlightText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', xl: '20px' },
    fontWeight: { base: '600', lg: '500' },
    lineHeight: { base: '25px', xl: '28px' },
    color: primaryTheme.colors.brand.lemonYellow,
    maxWidth: { xl: '458px' },
  },
});

const FormDescription = ({ path }: { path: string }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  const [isMobileView] = useMediaQuery('(max-width: 767px)');
  return (
    <Box
      ref={ref}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      padding={{ base: '12px 0 0', lg: path === '/grandopening' ? '75px 0 62px' : '58px 0' }}
      gap={{ base: '29px', lg: 'unset' }}
    >
      {path !== '/grandopening' && (
        <HighlightText animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>EVERYONE’S A WINNER!</HighlightText>
      )}
      <Title animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
        {path !== '/grandopening' ? (
          <Box>
            <strong>The First 100 People</strong>
            {isMobileView && <br />} to Sign Up For Our New Coral Springs Studio Will Receive
            <strong> Our Current Lowest Membership Rates!</strong>
          </Box>
        ) : (
          <Box>
            The Grand Opening of Our Coral Springs Studio Is Here, And We're Celebrating With{' '}
            <strong>Two Weeks of Unlimited FREE Classes!</strong>
          </Box>
        )}
      </Title>
      <Description
        animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
        maxWidth={{ base: path === '/grandopening' ? '328px' : 'auto', xl: path === '/grandopening' ? '450px' : '426px' }}
        fontSize={{ base: path === '/grandopening' ? '22px' : '18px', lg: path === '/grandopening' ? '24px' : '20px' }}
      >
        {path === '/grandopening'
          ? 'Enter your information here to book your first two weeks of classes for free. Free classes will be available from June 29th - July 14th.'
          : 'Would you like to lock in our current lowest membership rates for our new Coral Springs studio? Enter your details now so our Membership Advisor can reach out!'}
      </Description>
    </Box>
  );
};

export default FormDescription;
