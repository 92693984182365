import { Box, chakra, Flex, Grid, Text } from '@chakra-ui/react';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';
import { useRef } from 'react';

import { useInViewport } from 'react-in-viewport';
import { SingleWinner } from 'components';

const OuterContainer = chakra(Box, {
  baseStyle: {
    padding: { base: '63px 0 0', lg: '127px 0 0' },
    maxWidth: { xl: '1254px' },
    margin: { xl: 'auto' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '26px', xl: '40px' },
    fontWeight: '500',
    lineHeight: { base: '33.8px', xl: '52px' },
    color: primaryTheme.colors.brand.mediumGrey,
    textTransform: 'capitalize',
    whiteSpace: 'pre-line',
  },
});

type WinnersArray = {
  index: number;
  name: string;
  image: string;
};

type AllWinnersProps = {
  index: number;
  text: string;
  winnersArray: WinnersArray[];
};

const AllWinners = ({ index, text, winnersArray }: AllWinnersProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  return (
    <OuterContainer opacity={enterCount >= 1 ? 1 : 0} ref={ref}>
      <Flex flexDirection={{ base: 'row', md: 'column' }} alignItems={{ base: 'flex-end', md: 'unset' }}>
        <Title animation={inViewport || enterCount ? AnimationFrame.slideDown : 'none'}>{text} </Title>
      </Flex>
      <Grid
        templateRows={{ base: 'repeat(1fr)' }}
        templateColumns={winnersArray.length === 1 ? 'repeat(1fr)' : { base: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
        rowGap={{ base: '40px', xl: '106px' }}
        columnGap={{ base: '10px', lg: '20px', xl: 'unset' }}
        padding={{ base: '22px 0', lg: '55px 0 100px' }}
      >
        {winnersArray.map((winner) => {
          return <SingleWinner key={winner.index} name={winner.name} index={index} image={winner.image} />;
        })}
      </Grid>
    </OuterContainer>
  );
};

export default AllWinners;
