import { chakra, Flex, Text, useMediaQuery, Box, Divider } from '@chakra-ui/react';
import { BackGroundTwo } from 'assets';
import { LocationImageComp } from 'components';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame, useNavBarDimensions } from 'utils';
import { ShiftIconOne, ShiftIconTwo } from 'assets';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      md: `url(${ShiftIconTwo}), url(${ShiftIconOne})`,
      xl: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),url(${ShiftIconTwo}), url(${ShiftIconTwo}),url(${ShiftIconOne}),url(${ShiftIconOne})`,
    },
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: {
      md: '90% 100%, 0% 90%',
      xl: '0% 70%, 100% 70%, 105% 99%, 0% 130%',
      '3xlB': '0% 60%, 70% 70%, 90% 110%, 30% 130%',
    },
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    paddingTop: { base: '70px', lg: '224px' },
    alignItems: 'center',
    justifyContent: 'center',
    background: { lg: `rgba(0, 0, 0, 0.2) url(${BackGroundTwo})` },
    backgroundSize: { base: '100% 190%', lg: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
  },
});
const CustomDivider = chakra(Divider, {
  baseStyle: {
    background: primaryTheme.colors.brand.white,
    width: '90px',
    height: '4px',
    border: '0.5px solid transparent',
    opacity: '1',
    margin: { base: '39px auto 36px', lg: '80px auto 72px' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '30px', lg: '40px' },
    lineHeight: { base: '40px', lg: '52px' },
    textAlign: 'center',
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    whiteSpace: 'pre-line',
    margin: 'auto',
    maxWidth: { base: '80%', lg: '747px' },
    fontWeight: '500',
  },
});

const LookForwardSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  const { margin } = useNavBarDimensions();

  return (
    <OuterContainer>
      <Container
        paddingLeft={!isMobileView ? margin : '20px'}
        paddingRight={!isMobileView ? margin : '20px'}
        margin="auto"
        ref={ref}
        opacity={enterCount >= 1 ? 1 : 0}
      >
        <Box maxWidth="1240px">
          <LocationImageComp />
          <CustomDivider />

          <Title animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
            We look forward to rolling out our mats alongside you at our gorgeous new Coral Springs studio and bringing the Yoga
            Joint spirit to this beautiful corner of South Florida.
          </Title>
        </Box>
      </Container>
    </OuterContainer>
  );
};

export default LookForwardSection;
