import { Box, Button, chakra, Divider, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { BackgroundSeven } from 'assets';
import { primaryTheme } from 'theming';
import { AnimationFrame, useNavBarDimensions } from 'utils';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { Link } from 'react-router-dom';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: `rgba(0, 0, 0, 0.2) url(${BackgroundSeven})`,
    backgroundPosition: 'right, right',
    backgroundSize: { base: 'cover', md: 'cover' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
    margin: { base: '80px 0 0 ', md: '221px auto 0' },
    gap: { lg: '40px', xl: '130px' },
    maxWidth: { xl: '1254px' },
    flexDirection: { base: 'column', lg: 'row' },
  },
});

const LeftContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    gap: { base: '16px', lg: '80px' },
    width: { base: '100%', xl: '50%' },
    justifyContent: 'center',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '20px', xl: '32px' },
    fontWeight: { base: '600', lg: '500' },
    lineHeight: { base: '24px', xl: '38px' },
    color: primaryTheme.colors.brand.violet,
    textTransform: 'capitalize',
    whiteSpace: { lg: 'pre-line' },
    textAlign: { base: 'center', lg: 'unset' },
    margin: { base: '10px 0 20px', lg: 'unset' },
    maxWidth: '351px',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '37px', xl: '50px' },
    fontWeight: { base: '400', lg: '500' },
    lineHeight: { base: '44px', xl: '60px' },
    color: primaryTheme.colors.brand.white,
    maxWidth: { xl: '458px' },
    textTransform: 'capitalize',
    textAlign: { base: 'center', lg: 'unset' },
  },
});

const CustomDivider = chakra(Box, {
  baseStyle: {
    borderLeft: { base: '1px solid' },
    borderStyle: 'dottsolided !important',
    color: primaryTheme.colors.brand.white,
    opacity: '15%',
    alignSelf: 'center',
    position: 'absolute',
    left: '50%',
    height: '100%',
  },
});

const HighLightText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', xl: '18px' },
    fontWeight: '500',
    lineHeight: '27px',
    color: primaryTheme.colors.brand.lemonYellow,
    maxWidth: { base: '300px', md: 'unset', xl: '381px' },
    textAlign: { base: 'center', lg: 'unset' },
    margin: { base: 'auto', md: 'unset' },
  },
});

const HorizontalDivider = chakra(Divider, {
  baseStyle: {
    width: '66px',
    height: '4px',
    background: primaryTheme.colors.brand.white,
    opacity: 1,
    margin: { base: '46px 0', lg: '60px 0 46px' },
    alignSelf: { base: 'center', lg: 'unset' },
  },
});

const EveryOneIsAWinner = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  const [isMobileView] = useMediaQuery('(max-width: 767px)');
  const [isMobileViewWidth] = useMediaQuery('(max-width: 900px)');
  const { margin } = useNavBarDimensions();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <OuterContainer
      opacity={enterCount >= 1 ? 1 : 0}
      ref={ref}
      paddingLeft={!isMobileView ? margin : '20px'}
      paddingRight={!isMobileView ? margin : '20px'}
    >
      <Container position="relative" padding={{ base: '51px 0 57px', md: '68px 0' }}>
        <LeftContainer>
          <Description animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
            Didn’t see your name beside one of our prize packages?
          </Description>
          <Title animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
            Don’t worry, You’re still a winner!
          </Title>
        </LeftContainer>
        {!isMobileViewWidth && <CustomDivider />}
        <Box width={{ base: '100%', xl: '50%' }}>
          <Flex flexDirection="column">
            <Description
              fontWeight="400"
              textTransform="unset"
              fontSize={{ base: '20px', lg: '23px' }}
              lineHeight={{ base: '28px', lg: '32px' }}
              animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            >
              The first newcomers to sign up for membership to our Coral Springs studio before our Grand Opening will receive our
              lowest rate ever!
            </Description>
            <HorizontalDivider />
            <HighLightText animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
              Click the button before this limited-time offer ends to claim your special rate!
            </HighLightText>
            <Link to="/sale1" onClick={scrollToTop}>
              <Button
                variant="primary"
                size="primary"
                width={{ base: '100%', md: '299px' }}
                height="56px"
                _focus={{ outline: '0 !important' }}
                fontWeight="700"
                fontSize="16px"
                margin={{ base: '28px auto 0', xl: '40px 0 0' }}
                alignSelf={{ base: 'center', lg: 'unset' }}
                display="flex"
                animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
              >
                Everyone’s A Winner
              </Button>
            </Link>
          </Flex>
        </Box>
      </Container>
    </OuterContainer>
  );
};

export default EveryOneIsAWinner;
