import { Box, chakra, Flex, Image, Text, useMediaQuery, Divider } from '@chakra-ui/react';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame, useNavBarDimensions } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    paddingTop: { md: '120px' },
    background: 'linear-gradient(180deg, #091331 0%, rgba(7, 13, 34, 0) 100%)',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    paddingTop: { base: '49px', md: 'unset' },
    gap: { md: '30px', lg: '100px' },
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: { base: 'column', md: 'row' },
    marginBottom: { base: '58px', md: '100px', xl: '335px' },
  },
});

const LeftContainer = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '20px',
    width: { base: '100%', md: '70%', lg: '40%' },
  },
});

const RightContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: { lg: '28px' },
    width: { xl: '50%' },
    paddingTop: '20px',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '32px', xl: '50px' },
    fontWeight: '500',
    lineHeight: { base: '36px', xl: '60px' },
    textAlign: { base: 'center', lg: 'unset' },
    marginBottom: { base: '20px', lg: 'unset' },
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    backgroundClip: 'text',
    maxWidth: '520px',
    marginTop: { base: '20px', lg: 'unset' },
    whiteSpace: 'pre-line',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '17px', xl: '20px' },
    fontWeight: '300',
    lineHeight: { base: '26px', xl: '29px' },
    color: primaryTheme.colors.brand.white,
    marginBottom: { base: '30px', md: '20px', lg: '30px' },
    marginTop: { base: '40px', md: 'unset' },
    textAlign: { base: 'center', lg: 'unset' },
    maxWidth: '483px',
    whiteSpace: 'pre-line',
  },
});
const HighLightText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', md: '16px' },
    fontWeight: { base: '600', lg: '700' },
    lineHeight: { base: '28px', lg: '25px' },
    color: primaryTheme.colors.brand.lemonYellow,
    cursor: 'pointer',
    textAlign: { base: 'center', lg: 'unset' },
    whiteSpace: 'pre-line',
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    background: primaryTheme.colors.gradients.preSaleGradient,
    width: { base: '78px', lg: '112px' },
    height: { base: '5px', lg: '7px' },
    border: '0.5px solid transparent',
    opacity: '1',
    bgRepeat: 'no-repeat',
    margin: { base: 'auto', lg: 'unset' },
  },
});

type YjAppSpecProps = {
  index: number;
  title: string;
  description: string;
  image: string;
  onClick: () => void;
  path?: string;
};

const YjAppSpec = ({ index, title, description, image, onClick, path }: YjAppSpecProps) => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 700px)');

  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);

  return (
    <OuterContainer
      paddingTop={{ md: '40px', lg: '80px', xl: '120px' }}
      paddingLeft={!isMobileView ? margin : ''}
      paddingRight={!isMobileView ? margin : ''}
    >
      <Container
        ref={ref}
        opacity={enterCount >= 1 ? 1 : 0}
        paddingLeft={isMobileView ? margin : ''}
        paddingRight={isMobileView ? margin : ''}
      >
        {index % 2 === 1 && !isMobileView && (
          <LeftContainer position="relative" height="500px">
            <Image
              animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}
              src={image}
              maxHeight={{ lg: '712px' }}
              maxWidth={{ base: '60%', md: '100%', xl: '712px' }}
              alignSelf={{ base: 'center', lg: 'unset' }}
              position="absolute"
              top={{ md: '0%', lg: '-25%', xl: '-33%' }}
            />
          </LeftContainer>
        )}
        <RightContainer>
          <CustomDivider />
          <Title animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}>{title}</Title>
          {isMobileView && (
            <Image
              animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}
              src={image}
              maxHeight={{ md: '712px' }}
              maxWidth={{ base: '70%', lg: '712px' }}
              alignSelf={{ base: 'center', lg: 'unset' }}
            />
          )}
          <Flex flexDirection="column">
            <Description animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}>
              {description}
            </Description>
            <HighLightText onClick={onClick} animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}>
              {path === '/grandopening'
                ? `Secure Your 2 Weeks of${isMobileView ? '\n' : ' '}FREE Classes!`
                : 'LOCK IN YOUR LOWEST\nMEMBERSHIP RATE!'}
              <br />
            </HighLightText>
          </Flex>
        </RightContainer>
        {index % 2 === 0 && !isMobileView && (
          <LeftContainer position="relative" height="500px">
            <Image
              animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}
              src={image}
              maxHeight={{ lg: '712px' }}
              maxWidth={{ base: '60%', md: '100%', xl: '712px' }}
              alignSelf={{ base: 'center', lg: 'unset' }}
              position="absolute"
              top={{ md: '0%', lg: '-25%', xl: '-33%' }}
            />
          </LeftContainer>
        )}
      </Container>
    </OuterContainer>
  );
};

export default YjAppSpec;
