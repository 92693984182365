import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { SecondaryFooter, ScheduleHeroSection, TopBorder } from 'components';
import { useLocation } from 'react-router-dom';
import { SqueezeFormData } from 'utils';

const SchedulePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const data = location.state;
  return (
    <Box>
      <TopBorder />
      <ScheduleHeroSection isContest={location.pathname.includes('schedule1')} data={data as SqueezeFormData} />
      <SecondaryFooter isSchedulePage />
    </Box>
  );
};

export default SchedulePage;
