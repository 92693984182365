import { Box, useMediaQuery } from '@chakra-ui/react';
import { WinnersHero, WinnersHeroMob } from 'assets';
import {
  SecondaryFooter,
  WinnersHeroSection,
  WinnerNamesSection,
  ThankYouParticipants,
  EveryOneIsAWinner,
  LookForwardSection,
  WinnerPageMarquee,
} from 'components';
import { useRef } from 'react';

const WinnersPage = () => {
  const [isMobileView] = useMediaQuery('(max-width: 700px)');
  const formRef = useRef<HTMLDivElement>(null);
  const WinnersRef = useRef<HTMLDivElement>(null);

  const scrollToForm = () => {
    formRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const scrollToWinners = () => {
    WinnersRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <Box>
      <WinnerPageMarquee onClick={scrollToForm} />
      <WinnersHeroSection
        title={`Congratulations\nto All the Winners`}
        subTitle="Thank you for making our Yoga Joint Grand Opening Giveaway Contest a Success!"
        buttonText="Scroll down to find out if your name is among our 10 lucky winners!"
        image={isMobileView ? WinnersHeroMob : WinnersHero}
        onClick={scrollToWinners}
      />
      <Box ref={WinnersRef}>
        <WinnerNamesSection onClick={scrollToForm} />
      </Box>
      <Box ref={formRef}>
        <EveryOneIsAWinner />
      </Box>
      <ThankYouParticipants />
      <LookForwardSection />
      <SecondaryFooter />
    </Box>
  );
};

export default WinnersPage;
