import { Flex, chakra, Text, Box } from '@chakra-ui/react';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { ReloadIcon } from 'assets';

const Wrapper = chakra(Flex, {
  baseStyle: {
    borderTop: '2px solid rgba(255, 255, 255, 0.25)',
    borderBottom: '2px solid rgba(255, 255, 255, 0.25)',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
});
const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '37px', xl: '50px' },
    fontWeight: '400',
    lineHeight: { base: '44px', xl: '60px' },
    color: primaryTheme.colors.brand.white,
    textTransform: 'capitalize',
    whiteSpace: 'pre-line',
    letterSpacing: '-0.5px',
    maxWidth: '518px',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '19px', xl: '17px' },
    fontWeight: '500',
    lineHeight: { base: '25px', xl: '27px' },
    color: primaryTheme.colors.brand.white,
  },
});
const ReloadText = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: { base: '100%', md: '100%' },
    textTransform: 'uppercase',
    color: primaryTheme.colors.brand.violet,
    padding: '20px 0',
  },
});

const ThankYou = ({ setIsSubmitted }: { setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  return (
    <Wrapper>
      <Title animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>ThankYou!</Title>
      <Description animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
        A Membership Advisor will reach out within 48 hours!
      </Description>
      <Box
        onClick={() => setIsSubmitted(false)}
        display="flex"
        alignItems="center"
        justifyContent="center"
        margin="26px 0 42px"
        gap="10px"
        cursor="pointer"
      >
        <ReloadIcon />
        <ReloadText>RELOAD FORM</ReloadText>
      </Box>
    </Wrapper>
  );
};

export default ThankYou;
