import { Box, Button, chakra, Divider, useMediaQuery } from '@chakra-ui/react';
import { Stub, useNavBarDimensions } from 'utils';
import { AllWinners, Packages } from 'components';
import { ShiftIconFive, ShiftIconOne, ShiftIconSix, ShiftIconTwo } from 'assets';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      md: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${ShiftIconFive}), url(${ShiftIconSix}), url(${ShiftIconTwo}),url(${ShiftIconTwo}),url(${ShiftIconOne})`,
    },
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: {
      md: '-20% 0%, 130% 70%, 100% 20%, 110% -3%, 110% 90%',
      lg: '-10% 0%, 90% 70%, 100% 20%, 110% -3%, 110% 90%',
      '3xlB': '0% 0%, 70% 70%, 90% 30%, 90% -3%, 93% 90%',
    },
  },
});
const CustomDivider = chakra(Divider, {
  baseStyle: {
    color: 'rgba(98, 103, 142, 0.7)',
    border: '0.25px solid',
    marginTop: { base: '60px', lg: '145px' },
  },
});

type WinnerNamesSectionProps = {
  onClick: () => void;
};

const WinnerNamesSection = ({ onClick }: WinnerNamesSectionProps) => {
  const [isMobileView] = useMediaQuery('(max-width: 770px)');
  const { margin } = useNavBarDimensions();
  return (
    <OuterContainer>
      <Box marginLeft={!isMobileView ? margin : '20px'} marginRight={!isMobileView ? margin : '20px'}>
        {Stub.PackageWinners.map((item) => (
          <Box maxWidth="1240px" margin="auto">
            <AllWinners key={item.index} text={item.text} index={Number(item.index)} winnersArray={item.winner} />
            <Packages
              key={item.package.index}
              description={item.package.description}
              card={item.package.card}
              background={item.package.background}
              color={item.package.color}
              onClick={onClick}
            />
            {isMobileView && (
              <Button
                variant="primary"
                size="primary"
                width={{ base: '100%', md: '288px' }}
                height="56px"
                _focus={{ outline: '0 !important' }}
                fontWeight="700"
                fontSize="14px"
                cursor="pointer"
                onClick={onClick}
                alignSelf="center"
                display="flex"
                margin="22px auto 0"
              >
                CLICK HERE IF YOU DIDN’T WIN
              </Button>
            )}
            {item.index < 3 && <CustomDivider />}
          </Box>
        ))}
      </Box>
    </OuterContainer>
  );
};

export default WinnerNamesSection;
