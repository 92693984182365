import { Box, chakra, Divider, Flex, useMediaQuery, Text, Image } from '@chakra-ui/react';
import {
  CoralSpringRegisterHero,
  CoralSpringRegisterHeroMob,
  ShiftIconFive,
  ShiftIconOne,
  ShiftIconSix,
  ShiftIconTwo,
  YogaJointLogoWithTextSmall,
} from 'assets';
import { AnimationFrame, SqueezeFormData, Stub, useNavBarDimensions } from 'utils';
import { useRef } from 'react';
import { primaryTheme } from 'theming';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      md: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url(${ShiftIconFive}), url(${ShiftIconSix}), url(${ShiftIconOne}) ,url(${ShiftIconTwo}), url(${ShiftIconOne})`,
      lg: `url(${ShiftIconFive}), url(${ShiftIconSix}), url(${ShiftIconOne}), url(${ShiftIconFive}),url(${ShiftIconSix}),url(${ShiftIconOne})`,
    },
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: {
      md: '150% 100%, 130% 100%, 0% 100%, 0% 300% !important',
      lg: '0% 100%, 0% 80%, 10% 130%, 80% 60%, 80% 100%, 120% 80% !important',
      '3xlB': '0% 100%, 0% 80%, 10% 130%, 75% 80%, 80% 100%, 100% 100% !important',
    },
  },
});
const InnerContainer = chakra(Box, {
  baseStyle: {
    background: {
      base: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${CoralSpringRegisterHeroMob})`,
      md: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${CoralSpringRegisterHero})`,
    },
    backgroundSize: {
      sm: '100% 70%',
      smAA: '100%',
      md: '100%',
      '3xlB': '100% 100%',
    },
    backgroundRepeat: 'no-repeat !important',
    paddingBottom: { '3xlB': '80px' },
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    paddingTop: { base: '30px', lg: 'unset' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: 'column',
    textAlign: 'center',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '28px', lg: '50px' },
    fontWeight: '500',
    lineHeight: { base: '29px', lg: '55px' },
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    whiteSpace: 'pre-line',
    maxWidth: { lg: '909px' },
    span: {
      fontSize: { base: '45px', lg: '76px' },
      lineHeight: { base: '80px', lg: '104px' },
    },
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '15px', lg: '20px' },
    fontWeight: '400',
    lineHeight: { base: '21px', lg: '28px' },
    letterSpacing: '4.5%',
    color: primaryTheme.colors.brand.white,
    maxWidth: { lg: '717px' },
  },
});

const HighLightText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '28px', lg: '35px' },
    fontWeight: '400',
    lineHeight: { base: '49px', lg: '70px' },
    letterSpacing: '4.5%',
    color: primaryTheme.colors.brand.lemonYellow,
    maxWidth: { lg: '717px' },
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    background: primaryTheme.colors.gradients.preSaleGradient,
    width: { base: '69px', lg: '112px' },
    height: { base: '4.3px', lg: '7px' },
    border: '0.5px solid transparent',
    opacity: '1',
    bgRepeat: 'no-repeat',
  },
});

const CustomDividerGrey = chakra(Divider, {
  baseStyle: {
    background: primaryTheme.colors.brand.white,
    width: '100%',
    height: '0px',
    border: '0.5px solid transparent',
    opacity: '0.3',
    bgRepeat: 'no-repeat',
    margin: '0px auto',
  },
});
type HeroSectionProps = {
  isContest?: boolean;
  data?: SqueezeFormData;
};

const HeroSection = ({ data, isContest = false }: HeroSectionProps) => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  const ref = useRef<HTMLDivElement>(null);
  return (
    <OuterContainer>
      <InnerContainer ref={ref} paddingLeft={!isMobileView ? margin : ''} paddingRight={!isMobileView ? margin : ''}>
        {!isMobileView && (
          <Box padding="40px 0 108px 0">
            <YogaJointLogoWithTextSmall />
          </Box>
        )}
        <Container paddingLeft={isMobileView ? margin : ''} paddingRight={isMobileView ? margin : ''}>
          {isMobileView && (
            <Box margin="0 0 48px 0">
              <YogaJointLogoWithTextSmall width="158px" height="39px" />
            </Box>
          )}
          <CustomDivider />
          <Title textTransform="capitalize" animation={AnimationFrame.slideLeft}>
            <span>Thank You</span>
            <br />
            {data?.path === '/sale1' || isContest
              ? 'For Participating In Our Grand Opening Giveaway Contest!'
              : 'for Your Interest In Our Coral Springs Studio!'}
          </Title>
          <Box
            display="flex"
            gap={{
              sm: '0.5em',
              smA: '14px',
              md: '14px',
              lg: '21px',
            }}
            margin={{ base: '22px 0', lg: '44px 0' }}
          >
            {Stub.ThankYouPageImages.map((item) => (
              <Image
                animation={AnimationFrame.fadeOut}
                width={{ sm: '48px', smAA: '58px', lg: '72px' }}
                height={{ sm: '48px', smAA: '56px', lg: '72px' }}
                src={item}
              />
            ))}
          </Box>
          <Description animation={AnimationFrame.slideRight} marginBottom={{ base: '22px', lg: '36px' }}>
            {data?.path === '/sale1' || isContest ? (
              <Box>
                <strong>In this contest, everyone is a winner!</strong> That's why we're offering you a chance to{' '}
                <strong>
                  lock in our current lowest rate on membership for our beautiful new Yoga Joint Coral Springs studio!
                </strong>
              </Box>
            ) : data?.path === '/sale2' ? (
              <Box>
                For a limited time, we're offering you <strong>a chance to lock in our current lowest rate on membership</strong>
                for our beautiful new Yoga Joint Coral Springs studio!
              </Box>
            ) : (
              <Box textTransform="capitalize" fontSize={{ base: '16px', lg: '24px' }}>
                For a limited time, <strong>our grand opening special pricing on membership</strong> will be available to all Yoga
                Joint newcomers!
              </Box>
            )}
          </Description>
          <Box
            animation={AnimationFrame.slideLeft}
            maxWidth="684px"
            margin="auto"
            display="flex"
            flexDirection="column"
            gap={{ base: '18px', lg: '32px' }}
          >
            <CustomDividerGrey />
            <Description fontWeight="500">
              {data?.path ? (
                'To take advantage of this special offer, be one of the first 100 people to reach out to us via phone, text, or live chat.'
              ) : (
                <Box maxWidth={{ base: '271px', md: 'unset' }}>
                  To lock in this special rate before it's too late, reach out to us via phone, text, or live chat.
                </Box>
              )}
            </Description>
            <Box>
              <Description fontWeight="600">Call or text us at:</Description>
              <HighLightText>
                <a href="tel:9548366714" style={{ textDecoration: 'none' }}>
                  954-836-6714
                </a>
              </HighLightText>
              <Description fontWeight="600">Or chat with us live below!</Description>
            </Box>

            <CustomDividerGrey />
          </Box>
          <Box animation={AnimationFrame.slideRight} width="100%" padding={{ base: '42px 0 250px', lg: '86px 0 373px 0' }}>
            <Title
              fontSize={{ base: '19px', lg: '30px' }}
              lineHeight={{ base: '25px', lg: '40px' }}
              maxWidth={{ lg: '600px' }}
              margin="0 auto"
              textTransform={{ base: !data?.path ? 'capitalize' : 'unset', lg: 'unset' }}
            >
              We look forward to expanding our Yoga Joint community and welcoming you to our gorgeous new studio!
            </Title>
          </Box>
        </Container>
      </InnerContainer>
    </OuterContainer>
  );
};

export default HeroSection;
