import { Box } from '@chakra-ui/react';

const DownArrow = () => {
  return (
    <Box as="svg" width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5011 0H13.5011V18L21.7511 9.75L23.8811 11.88L12.0011 23.76L0.121094 11.88L2.25109 9.75L10.5011 18V0Z"
        fill="white"
      />
    </Box>
  );
};

export default DownArrow;
