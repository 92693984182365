import { Box, chakra, Flex, useMediaQuery, Text, Button } from '@chakra-ui/react';
import { CoralSpringRegisterHero, CoralSpringRegisterHeroMob, YogaJointLogoWithTextSmall } from 'assets';
import { PrimaryInput, TextContentColumn } from 'components';
import { AnimationFrame, useNavBarDimensions } from 'utils';
import { primaryTheme } from 'theming';
import { useRef } from 'react';
import { Form, Formik } from 'formik';
import { getLowRatesForm2 } from 'utils/yupSchemas';
import { useHistory } from 'react-router-dom';
import { submitSqueezeForm } from 'utils/hubspot';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      base: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${CoralSpringRegisterHeroMob})`,
      md: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${CoralSpringRegisterHero})`,
      '3xlB': `linear-gradient(to top,rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url(${CoralSpringRegisterHero})`,
    },
    backgroundSize: {
      sm: '100% 70%',
      smAA: '100%',
      md: 'cover',
      '3xlB': '100% 120%',
    },
    backgroundRepeat: 'no-repeat !important',
    paddingBottom: { '3xlB': '80px' },
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    paddingTop: { base: '30px', lg: 'unset' },
    gap: { lg: '40px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: 'column',
    paddingBottom: { xl: '125px' },
  },
});

const Disclaimer = chakra(Text, {
  baseStyle: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    color: primaryTheme.colors.brand.white,
    marginTop: '24px',
    opacity: '65%',
  },
});

const FieldContainer = chakra(Flex, {
  baseStyle: {
    padding: { xl: '62px 0' },
    flexDirection: 'column',
  },
});

const FieldRow = chakra(Flex, {
  baseStyle: {
    flexDirection: { base: 'column', lg: 'row' },
    gap: { lg: '78px' },
    width: '100%',
  },
});

const HeroSection = () => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const id =
    history.location.pathname === '/register' ? '31521b20-7be2-4191-9ec2-d7eb937c7e7a' : 'b5af4623-9f9d-48c4-908c-a162cd399893';
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/21517867/${id}`;

  return (
    <OuterContainer ref={ref} paddingLeft={!isMobileView ? margin : ''} paddingRight={!isMobileView ? margin : ''}>
      {!isMobileView && (
        <Box padding="40px 0 108px 0">
          <YogaJointLogoWithTextSmall />
        </Box>
      )}
      <Container paddingLeft={isMobileView ? margin : ''} paddingRight={isMobileView ? margin : ''}>
        {isMobileView && (
          <Box margin="0 0 48px 0">
            <YogaJointLogoWithTextSmall width="158px" height="39px" />
          </Box>
        )}
        <TextContentColumn
          title={
            history.location.pathname === '/register'
              ? isMobileView
                ? `Yoga Joint\nCoral Springs Is Opening!`
                : 'Yoga Joint Coral Springs Is Opening!'
              : "Let's Celebrate the Grand Opening of Our Coral Springs Studio!"
          }
          description={
            history.location.pathname === '/register'
              ? 'For the <strong>first 100 people</strong>, we are offering a chance to lock in our lowest membership rate ever!'
              : 'New to Yoga Joint? Reach out today to <strong>lock in our grand opening special pricing</strong> on membership Before It’s Too Late!'
          }
          isRegisterPage
          path={history.location.pathname}
        />
        <Box marginTop={{ base: '40px', lg: 'unset' }} maxWidth="463px">
          <Formik
            validationSchema={getLowRatesForm2}
            initialValues={getLowRatesForm2.getDefault()}
            onSubmit={async (values) => {
              await submitSqueezeForm({
                fullname: values.fullname,
                email: values.email,
                url,
              });
              history.push({
                pathname: history.location.pathname === '/register' ? '/sale2' : 'grandopening',
                state: {
                  email: values.email,
                  fullname: values.fullname,
                },
              });
            }}
          >
            {() => (
              <Form>
                <FieldContainer ref={ref}>
                  <FieldRow animation={AnimationFrame.fadeOut}>
                    <PrimaryInput name="fullname" label="Your Full Name *" type="text" placeholder="Full Name" required />
                  </FieldRow>
                  <FieldRow animation={AnimationFrame.fadeOut}>
                    <PrimaryInput name="email" label="Email *" type="email" placeholder="Email Address" required />
                  </FieldRow>

                  <FieldRow animation={AnimationFrame.fadeOut}>
                    <Button
                      variant="primary"
                      size="primary"
                      width={{ base: '100%', md: '470px' }}
                      height="56px"
                      _focus={{ outline: '0 !important' }}
                      fontWeight="700"
                      fontSize={{ sm: '12px', smAA: '14px', smA: history.location.pathname === '/register' ? '14px' : '18px' }}
                      letterSpacing={{ sm: '-0.1px', smAA: 'unset' }}
                      margin={{ base: '20px auto 0', xl: '10px 0 0' }}
                      animation={AnimationFrame.fadeOut}
                      cursor="pointer"
                      type="submit"
                    >
                      {history.location.pathname === '/register'
                        ? 'OPT IN TO LOCK YOUR PRE SALE RATE!'
                        : 'LOCK IN THIS SPECIAL RATE NOW!'}
                    </Button>
                    <input type="hidden" name="reflink" value="" />
                  </FieldRow>
                  <FieldRow animation={AnimationFrame.fadeOut}>
                    <Disclaimer>
                      By entering, you agree to receive emails from Yoga Joint about news, updates, offers, and more. We respect
                      your privacy. Unsubscribe at any time.
                      <br />
                      <br />
                      {history.location.pathname === '/register'
                        ? 'Pre-sale rates are for new members only'
                        : '*Special rate applies to new members only'}
                    </Disclaimer>
                  </FieldRow>
                </FieldContainer>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </OuterContainer>
  );
};

export default HeroSection;
