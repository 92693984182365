import { Box } from '@chakra-ui/react';
import {
  SecondaryFooter,
  StudiosDescription,
  HeroSection,
  TransformSection,
  UniqueClasses,
  AppSection,
  PreSaleRateForm,
  PromoBar,
  LocationSection,
  TopBorder,
} from 'components';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { SqueezeFormData } from 'utils';

const EveryOneIsWinnerPage = () => {
  const formRef = useRef<HTMLDivElement>(null);

  const scrollToForm = () => {
    formRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const location = useLocation();
  const data = location.state;
  return (
    <Box>
      <PromoBar isPromoClosed={false} onClick={scrollToForm} path={location.pathname} />
      <TopBorder />
      <HeroSection onClick={scrollToForm} path={location.pathname} />
      {location.pathname === '/grandopening' && (
        <Box ref={formRef}>
          <PreSaleRateForm data={data as SqueezeFormData} path={location.pathname} />
        </Box>
      )}
      <TransformSection onClick={scrollToForm} path={location.pathname} />
      <StudiosDescription onClick={scrollToForm} path={location.pathname} />
      <UniqueClasses onClick={scrollToForm} path={location.pathname} />
      <AppSection onClick={scrollToForm} path={location.pathname} />
      <Box ref={formRef}>
        <PreSaleRateForm data={data as SqueezeFormData} path={location.pathname} />
      </Box>
      <LocationSection path={location.pathname} />
      <SecondaryFooter />
    </Box>
  );
};

export default EveryOneIsWinnerPage;
