import * as yup from 'yup';

export const getLowRates = yup.object({
  email: yup.string().default('').email('Enter a valid email').required('Email is required'),
  fullname: yup.string().default('').required('Name is required').min(2, 'Name is too short').max(50, 'Name is too long'),
  telephone: yup
    .string()
    .default('')
    .required('Telephone Number is required')
    .matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Telephone Number is invalid'),
});

export const getLowRatesForm2 = yup.object({
  email: yup.string().default('').email('Enter a valid email').required('Email is required'),
  fullname: yup.string().default('').required('Name is required').min(2, 'Name is too short').max(50, 'Name is too long'),
});

const yupSchemas = {
  getLowRates,
  getLowRatesForm2,
};

export default yupSchemas;
