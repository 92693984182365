import { chakra, Flex, Image } from '@chakra-ui/react';

interface WinnerImagesProps {
  image: string;
  width: string;
  height: string;
  isSmall?: boolean;
}

const Container = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    borderRadius: { lg: '3px' },
  },
});

const WinnerImages = ({ image, width, height, isSmall }: WinnerImagesProps) => {
  return (
    <Container
      paddingLeft={{ base: isSmall ? '6px' : '10px', lg: isSmall ? '10px' : '20px' }}
      gap={{ base: isSmall ? '6px' : '10px', lg: isSmall ? '10px' : '20px' }}
    >
      <Image src={image} width={width} height={height} />
    </Container>
  );
};

export default WinnerImages;
