import { Box, chakra, Flex, useMediaQuery, Button, AspectRatio } from '@chakra-ui/react';
import {
  BackGroundTwo,
  ShiftIconFive,
  ShiftIconOne,
  ShiftIconSix,
  ThreeForFreeBgMob,
  YogaJointLogoWithTextSmall,
  ShiftIconTwo,
  Ellipse,
} from 'assets';
import { TextContentColumn } from 'components';
import { AnimationFrame, hasNativeHLS, useNavBarDimensions } from 'utils';
import ReactPlayer from 'react-player';
import { useRef } from 'react';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      md: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url(${ShiftIconFive}), url(${ShiftIconSix}), url(${ShiftIconOne}) ,url(${ShiftIconTwo}), url(${ShiftIconOne})`,
      lg: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${ShiftIconFive}), url(${ShiftIconSix}), url(${ShiftIconOne}), url(${ShiftIconFive}),url(${ShiftIconFive}),url(${ShiftIconTwo}),url(${ShiftIconOne}), url(${ShiftIconOne})`,
    },
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: {
      md: '150% 0%, 130% 100%, 0% 0%, 0% 0% !important',
      lg: '100% 50%, 90% -20%, 90% 20%, 0% 80%, 10% -10%, 0% -90%, 10% 20%, 50% -10% !important',
      '3xlB': '100% 50%, 90% -20%, 90% 20%, 17% 70%, 10% -20%, 0% -100%, 15% 30%, 50% -30% !important',
    },
  },
});

const InnerContainer = chakra(Box, {
  baseStyle: {
    background: {
      base: 'linear-gradient(180deg, #181c43 0%, rgba(35, 41, 96, 0) 20%)',
      md: `url(${BackGroundTwo})`,
    },
    backgroundSize: { base: 'contain', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    paddingTop: { base: '30px', lg: 'unset' },
    background: { base: `url(${ThreeForFreeBgMob})`, md: 'none' },
    gap: { lg: '40px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: 'column',
  },
});

type HeroSectionProps = {
  onClick?: () => void;
  path?: string;
};

const HeroSection = ({ onClick, path }: HeroSectionProps) => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  const videoRef = useRef<ReactPlayer>(null);
  return (
    <OuterContainer>
      <InnerContainer>
        <Box background={`url(${Ellipse})`} bgRepeat="no-repeat" backgroundSize="contain" paddingTop={{ lg: '62px' }}>
          {!isMobileView && (
            <Box margin="0 0 80px 100px">
              <YogaJointLogoWithTextSmall />
            </Box>
          )}
          <Container
            paddingLeft={isMobileView ? margin : ''}
            paddingRight={isMobileView ? margin : ''}
            paddingBottom={{ base: path !== '/grandopening' ? '155px' : '0', md: path === '/grandopening' ? '91px' : '155px' }}
          >
            {isMobileView && (
              <Box margin="0 0 28px 0">
                <YogaJointLogoWithTextSmall />
              </Box>
            )}
            <TextContentColumn
              title={
                path === '/grandopening'
                  ? 'The Grand Opening of Our Coral Springs Studio Is Here!'
                  : 'Yoga Joint Coral Springs Is Opening!'
              }
              description={
                path === '/grandopening'
                  ? `Come In and Enjoy <strong>2 Weeks of Unlimited Free Hot Flow and FIIT Classes On Us!</strong>`
                  : `Be One of the First 100 People to Lock in <strong>your lowest membership rate</strong> today!`
              }
              path={path}
            />
            {(!isMobileView || (isMobileView && path !== '/grandopening')) && (
              <AspectRatio ratio={16 / 9} maxW="100vw" maxH="100vw" width="100%" overflow="hidden">
                <Box position="relative" margin={{ base: '20px 0 30px', lg: '0 0 10px' }}>
                  <ReactPlayer
                    muted
                    playing
                    loop
                    ref={videoRef}
                    controls
                    width="100%"
                    height="100%"
                    url="https://d2m1fy87s5v0ss.cloudfront.net/VIDEOS/AppleHLS1/Studio%20Drone%20Tour%20v5.m3u8"
                    config={{
                      file: {
                        forceHLS: !hasNativeHLS(),
                        forceVideo: true,
                        hlsVersion: '1.3.0',
                        attributes: {
                          autoPlay: true,
                          playsInline: true,
                          controlsList: 'fullscreen',
                          disablePictureInPicture: true,
                          style: {
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </AspectRatio>
            )}
            {path !== '/grandopening' && (
              <Button
                variant="primary"
                size="primary"
                width={{ base: '100%', md: '477px' }}
                height="56px"
                _focus={{ outline: '0 !important' }}
                fontWeight="700"
                fontSize="16px"
                margin={{ base: '27px auto 0', xl: '10px 0 0' }}
                animation={AnimationFrame.fadeOut}
                cursor="pointer"
                onClick={onClick}
              >
                LOCK IN YOUR {path === '/grandopening' ? 'SPECIAL' : 'LOWEST'} {isMobileView && <br />}MEMBERSHIP RATE!
              </Button>
            )}
          </Container>
        </Box>
      </InnerContainer>
      {isMobileView && path === '/grandopening' && (
        <AspectRatio ratio={16 / 9} maxW="100vw" maxH="100vw" width="100%" overflow="hidden">
          <Box position="relative" margin={{ base: '20px 0 30px', lg: '0 0 10px' }}>
            <ReactPlayer
              muted
              playing
              loop
              ref={videoRef}
              controls
              width="100%"
              height="100%"
              url="https://d2m1fy87s5v0ss.cloudfront.net/VIDEOS/AppleHLS1/Studio%20Drone%20Tour%20v5.m3u8"
              config={{
                file: {
                  forceHLS: !hasNativeHLS(),
                  forceVideo: true,
                  hlsVersion: '1.3.0',
                  attributes: {
                    autoPlay: true,
                    playsInline: true,
                    controlsList: 'fullscreen',
                    disablePictureInPicture: true,
                    style: {
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    },
                  },
                },
              }}
            />
          </Box>
        </AspectRatio>
      )}
    </OuterContainer>
  );
};

export default HeroSection;
