import { Box, chakra, Text, Flex, Divider } from '@chakra-ui/react';
import { AnimationFrame } from 'utils';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';

const OuterContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'space-between',
    borderRadius: '5px',
    width: { md: '100%', lg: 'unset' },
    margin: { md: 'auto', lg: 'unset' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', xl: '30px' },
    fontWeight: '600',
    lineHeight: '100%',
    color: primaryTheme.colors.brand.white,
    textTransform: 'uppercase',
    whiteSpace: 'pre-line',
  },
});
const CustomDivider = chakra(Divider, {
  baseStyle: {
    width: '12px',
    border: '0.5px solid transparent',
    opacity: '1',
    borderRadius: '6px',
  },
});

type SinglePackageProps = {
  name: string;
  image: string;
  index?: number;
};

const SinglePackage = ({ name, index }: SinglePackageProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  return (
    <OuterContainer
      opacity={enterCount >= 1 ? 1 : 0}
      ref={ref}
      animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
      position="relative"
    >
      <Flex marginTop="auto" justifyContent="flex-end" flexDirection="column">
        {index === 1 ? (
          <Flex
            flexDirection={{ base: 'column-reverse', lg: 'row' }}
            alignItems={{ base: 'flex-start', lg: 'flex-end' }}
            justifyContent={{
              base: 'flex-start',
              lg: 'space-between !important',
            }}
            gap={{ base: '20px', lg: 'unset' }}
          >
            <Flex alignItems="center" gap="26px">
              <CustomDivider height={{ base: '100px', lg: '62px' }} background="#43507E" />
              <Title fontSize={{ base: '60px', lg: '85px' }}>{name}</Title>
            </Flex>
          </Flex>
        ) : (
          <Flex gap="22px" flexDirection={{ base: 'column', lg: 'row' }} alignItems={{ lg: 'center' }}>
            <Flex alignItems="center" gap={{ base: '14px', lg: '33px' }}>
              <CustomDivider
                width={{ base: '6px', lg: '10px' }}
                height={{ base: '52px', lg: '76px' }}
                background={index === 2 ? '#625525' : '#636571'}
              />
              <Title fontSize={{ base: '25px', lg: '40px' }} lineHeight={{ base: '28px', lg: '48px' }}>
                {name}
              </Title>
            </Flex>
          </Flex>
        )}
      </Flex>
    </OuterContainer>
  );
};

export default SinglePackage;
