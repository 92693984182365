import { Box, chakra, Flex, Image, Text, useMediaQuery, Divider } from '@chakra-ui/react';
import { BackGroundTwo } from 'assets';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame, Stub, useNavBarDimensions } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    paddingTop: { md: '220px' },
    background: {
      md: `url(${BackGroundTwo})`,
    },
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    paddingTop: { base: '70px', md: 'unset' },
    gap: { lg: '50px', xl: '122px' },
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: { base: 'column', lg: 'row' },
  },
});

const LeftContainer = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '20px',
    width: { base: '100%', lg: '70%', xl: '50%' },
  },
});

const RightContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '58px',
    width: { lg: '70%', xl: '50%' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '36px', xl: '50px' },
    fontWeight: '500',
    lineHeight: { base: '46px', xl: '65px' },
    marginBottom: { base: '32px', lg: 'unset' },
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    textTransform: 'Capitalize',
    maxWidth: '480px',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', xl: '20px' },
    fontWeight: { base: '400', xl: '300' },
    lineHeight: { base: '26px', xl: '29px' },
    color: primaryTheme.colors.brand.white,
    marginBottom: { base: '20px', md: '30px' },
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    width: '112px',
    height: '7px',
    backgroundImage: primaryTheme.colors.gradients.preSaleGradient,
    border: '1px solid transparent',
    boxSizing: 'border-box',
    opacity: 1,
    bgRepeat: 'no-repeat',
  },
});

const ThankYouParticipants = () => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');

  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);

  return (
    <OuterContainer paddingLeft={!isMobileView ? margin : ''} paddingRight={!isMobileView ? margin : ''}>
      <Container
        ref={ref}
        opacity={enterCount >= 1 ? 1 : 0}
        paddingLeft={isMobileView ? margin : ''}
        paddingRight={isMobileView ? margin : ''}
      >
        <LeftContainer>
          <CustomDivider />
          <Title animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}>
            Thank you to everyone who participated and helped to make this contest a success
          </Title>
        </LeftContainer>
        <RightContainer>
          <Flex flexDirection="column">
            <Flex
              gap="46px"
              rowGap={{ base: '22', lg: '28px' }}
              columnGap={{ base: '36px', lg: '46px' }}
              flexWrap="wrap"
              maxWidth="450px"
              marginBottom={{ base: '37px', lg: '75px' }}
            >
              {Stub.PartcipantsArray.map((item) => (
                <Image
                  animation={inViewport && enterCount === 1 ? AnimationFrame.fadeOut : 'none'}
                  width="60px"
                  height="60px"
                  borderRadius="50%"
                  src={item}
                />
              ))}
            </Flex>

            <Description animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}>
              All prize winners will receive an email within the next 24 hours with instructions on how to claim their prize
              package. We can’t wait to welcome you to our new Coral Springs studio!
            </Description>
            <Description animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}>
              <strong>We can’t wait to welcome you to our new Coral Springs studio!</strong>
            </Description>
          </Flex>
        </RightContainer>
      </Container>
    </OuterContainer>
  );
};

export default ThankYouParticipants;
