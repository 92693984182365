import { useRef } from 'react';
import { StudioDescriptionCard } from 'components';
import { AnimationFrame, Stub, useNavBarDimensions } from 'utils';
import { useInViewport } from 'react-in-viewport';
import { SimpleGrid, Box, chakra, Divider, Text, Flex, Button, useMediaQuery } from '@chakra-ui/react';
import { BackGroundTwo, ShiftIconOne, ShiftIconSix, ShiftIconThree, ShiftIconTwo, ThreeForFreeBgMob } from 'assets';
import { primaryTheme } from 'theming';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: { base: `url(${ThreeForFreeBgMob})`, md: `url(${BackGroundTwo})` },
    backgroundSize: '100% 33%',
    backgroundRepeat: 'repeat-y !important',
  },
});
const InnerContainer = chakra(Box, {
  baseStyle: {
    background: `
    linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${ShiftIconOne}), url(${ShiftIconSix})`,
    backgroundPosition: { base: '20% 5%, 20% 10%', '3xlB': '40% 2%, 40% 10%' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Box, {
  baseStyle: {
    paddingTop: { base: '150px', md: '90px', xl: '150px' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '32px', xl: '50px' },
    fontWeight: '500',
    lineHeight: { base: '36px', xl: '60px' },
    whiteSpace: 'pre-line',
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    textAlign: 'center',
    maxWidth: { base: '80%', lg: '470px' },
  },
});

const SubTitle = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', xl: '20px' },
    fontWeight: '300',
    lineHeight: { base: '26px', xl: '29px' },
    color: primaryTheme.colors.brand.white,
    textAlign: 'center',
    maxWidth: { base: '85%', lg: '686px' },
    marginTop: { lg: '20px' },
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    background: primaryTheme.colors.gradients.preSaleGradient,
    width: '112px',
    height: '7px',
    border: '0.5px solid transparent',
    opacity: '1',
    bgRepeat: 'no-repeat',
    marginBottom: '5px',
  },
});

type StudiosDescriptionProps = {
  onClick: () => void;
  path?: string;
};

const StudiosDescription = ({ onClick, path }: StudiosDescriptionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref, { threshold: 0.2 });
  const animationDirection = [AnimationFrame.slideRight, AnimationFrame.slideLeft];
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');

  return (
    <OuterContainer>
      <InnerContainer paddingLeft={!isMobileView ? margin : ''} paddingRight={!isMobileView ? margin : ''}>
        <Container justifyContent="center" maxWidth={{ md: '1254' }} margin={{ md: 'auto' }} ref={ref}>
          <Flex
            flexDirection="column"
            alignItems="center"
            gap="30px"
            opacity={enterCount >= 1 ? 1 : 0}
            animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            marginBottom={{ base: '50px', lg: '254px' }}
          >
            <CustomDivider />
            <Title>World Class Hot Yoga Studios</Title>
            <SubTitle>
              Our studios are flawless in design and unmatched in quality.
              <br />
              <br />
              Every Yoga Joint studio is outfitted with state-of-the-art sound, lighting systems that change color to match the
              mood of the class, numbered mat spaces, best-in-class yoga floors, and the highest-quality infrared heating systems
              on the market.
            </SubTitle>
          </Flex>

          <SimpleGrid
            width="100%"
            columns={{ lg: 2 }}
            opacity={enterCount >= 1 ? 1 : 0}
            minChildWidth={{ base: '100%', md: '35%' }}
            overflow={{ base: 'hidden', md: 'initial' }}
            background={` url(${ShiftIconTwo}), url(${ShiftIconThree}), url(${ShiftIconTwo}),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${ShiftIconTwo}), url(${ShiftIconTwo}), url(${ShiftIconOne})`}
            backgroundPosition="right top, right top, right center, left center, left bottom, right bottom, left center"
            backgroundRepeat="no-repeat"
            padding={{ base: '0 17px', lg: 'unset' }}
          >
            {Stub.StudiosDescriptionList.map((item, index) => (
              <StudioDescriptionCard
                key={item.id}
                item={item}
                index={index}
                animate={inViewport && enterCount === 1 ? animationDirection[index % 2] : 'none'}
              />
            ))}
          </SimpleGrid>
          <Button
            variant="primary"
            size="primary"
            width={{ base: '90%', md: '477px' }}
            height="56px"
            _focus={{ outline: '0 !important' }}
            fontWeight="700"
            fontSize="16px"
            margin={{ base: '0px auto 0', lg: '-70px auto 0' }}
            animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            cursor="pointer"
            onClick={onClick}
            alignSelf="center"
            display="flex"
            whiteSpace="pre-line"
          >
            {path === '/grandopening'
              ? `Secure your 2 weeks${isMobileView ? '\n' : ' '}of free classes`
              : `LOCK IN YOUR LOWEST${isMobileView ? '\n' : ' '}MEMBERSHIP RATE!`}
          </Button>
        </Container>
      </InnerContainer>
    </OuterContainer>
  );
};

export default StudiosDescription;
