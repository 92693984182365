import { Box, chakra, useMediaQuery } from '@chakra-ui/react';
import { YogaJointLogo } from 'assets';
import { primaryTheme } from 'theming';
import Marquee from 'react-fast-marquee';

const MarqueeContainer = chakra(Box, {
  baseStyle: {
    width: '100vw',
    display: { base: 'flex', md: 'unset' },
    background: primaryTheme.colors.brand.lemonYellow,
    fontSize: { base: '18px', md: '19px' },
    lineHeight: '20px',
    fontWeight: '700',
    position: 'fixed',
    zIndex: '100',
    span: {
      color: '#7631A1',
      fontSize: { base: '18px', md: '20px' },
      lineHeight: '20px',
      display: 'inline-block',
      fontWeight: '700',
    },
  },
});
const TextContainer = chakra(Box, {
  baseStyle: {
    gap: { base: '19px !important', xl: '36px' },
    alignItems: 'center',
    display: 'flex',
    padding: '10px 0',
  },
});

type WinnerNamesSectionProps = {
  onClick: () => void;
};

const WinnerPageMarquee = ({ onClick }: WinnerNamesSectionProps) => {
  const [isMobileView] = useMediaQuery('(max-width: 700px)');

  const getSpeed = () => {
    if (window.innerWidth < 768) return 70;
    return 120;
  };

  const textMsg = isMobileView
    ? `<span>Name not among our winners?</span> Don't worry. <u>Click here</u> to receive a limited-time offer on
  pre-sale memberships!`
    : `<span>EVERYONE’S A WINNER!</span> | Name not among our winners? Don't worry. <u>Click here</u> to receive a
  limited-time offer on pre-sale memberships!`;

  return (
    <Box>
      <MarqueeContainer cursor="pointer" onClick={onClick}>
        <Marquee speed={getSpeed()} loop={0}>
          <TextContainer marginRight={{ base: '40px', '3xlB': '50px' }}>
            <YogaJointLogo />
            <Box
              dangerouslySetInnerHTML={{
                __html: textMsg,
              }}
            />
            <TextContainer marginLeft={{ base: '40px', '3xlB': '50px' }}>
              <YogaJointLogo />
              <Box
                dangerouslySetInnerHTML={{
                  __html: textMsg,
                }}
              />
            </TextContainer>
          </TextContainer>
        </Marquee>
      </MarqueeContainer>
    </Box>
  );
};

export default WinnerPageMarquee;
