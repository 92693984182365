import { OptionsRouter } from 'react-typesafe-routes';
import { PreSalePage, RegisterPage, WinnersPage, SchedulePage, GetAppPage } from 'pages';

const defaultOptions = {
  isHomePage: false,
  isMailer: false,
  isAd: false,
};

const router = OptionsRouter(defaultOptions, (route) => ({
  contestwinners: route('contestwinners', {
    component: WinnersPage,
    options: {
      isHomePage: true,
    },
  }),
  // from contest winners page
  sale1: route('sale1', {
    component: PreSalePage,
  }),
  schedule1: route('schedule1', {
    component: SchedulePage,
  }),
  register: route('register', {
    component: RegisterPage,
  }),
  // from register page
  sale2: route('sale2', {
    component: PreSalePage,
  }),
  schedule2: route('schedule2', {
    component: SchedulePage,
  }),
  //flow 4
  // enrol: route('enrol', {
  //   component: RegisterPage,
  // }),
  grandopening: route('grandopening', {
    component: PreSalePage,
  }),
  getApp: route('get-app', {
    component: GetAppPage,
  }),
  // schedule: route('schedule', {
  //   component: SchedulePage,
  // }),
}));

export default router;
