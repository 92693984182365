import { Box } from '@chakra-ui/react';
import { SecondaryFooter, RegisterHeroSection, TopBorder } from 'components';

const RegisterPage = () => {
  return (
    <Box>
      <TopBorder />
      <RegisterHeroSection />
      <SecondaryFooter isRegisterPage />
    </Box>
  );
};

export default RegisterPage;
