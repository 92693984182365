import { useRef } from 'react';
import { ClassCard } from 'components';
import { AnimationFrame, Stub } from 'utils';
import { useInViewport } from 'react-in-viewport';
import { SimpleGrid, Box, chakra, Divider, Text, Flex, Button, useMediaQuery } from '@chakra-ui/react';
import { BackGroundTwo, ShiftIconOne, ShiftIconSix, ShiftIconThree, ShiftIconTwo } from 'assets';
import { primaryTheme } from 'theming';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      md: `url(${BackGroundTwo})`,
    },
    backgroundSize: { base: '150% 10%', md: '100% 33%' },
    backgroundRepeat: 'repeat-y !important',
  },
});

const InnerContainer = chakra(Box, {
  baseStyle: {
    paddingTop: { base: '30px', md: '90px', xl: '340px' },
    justifyContent: 'center',
    maxWidth: { md: '1254' },
    margin: { md: 'auto' },
  },
});

const Container = chakra(Box, {
  baseStyle: {
    background: {
      md: `
    linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${ShiftIconOne}), url(${ShiftIconSix}), url(${ShiftIconOne}), url(${ShiftIconSix})`,
    },
    backgroundPosition: '100% 0%, 120% 30%, 10% 50%, 30% 20%, 20% 2%',
    backgroundRepeat: 'no-repeat !important',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '33px', xl: '50px' },
    fontWeight: '500',
    lineHeight: { base: '40px', xl: '60px' },
    whiteSpace: 'pre-line',
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    textAlign: 'center',
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    background: primaryTheme.colors.gradients.preSaleGradient,
    width: '112px',
    height: '7px',
    border: '0.5px solid transparent',
    opacity: '1',
    bgRepeat: 'no-repeat',
    marginBottom: '5px',
  },
});
type UniqueClassesProps = {
  onClick: () => void;
  path?: string;
};

const UniqueClasses = ({ onClick, path }: UniqueClassesProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref, { threshold: 0.2 });
  const animationDirection = [AnimationFrame.slideRight, AnimationFrame.slideLeft];
  const [isMobileView] = useMediaQuery('(max-width: 800px)');

  return (
    <OuterContainer>
      <Container>
        <InnerContainer ref={ref}>
          <Flex
            flexDirection="column"
            alignItems="center"
            gap="30px"
            opacity={enterCount >= 1 ? 1 : 0}
            animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
          >
            <CustomDivider />
            <Title>Uniquely Branded Classes</Title>
          </Flex>

          <SimpleGrid
            width="100%"
            columns={{ lg: 2 }}
            minChildWidth={{ base: '100%', md: '35%' }}
            overflow={{ base: 'hidden', md: 'initial' }}
            background={` url(${ShiftIconTwo}), url(${ShiftIconThree}), url(${ShiftIconTwo}),
          linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
          url(${ShiftIconTwo}), url(${ShiftIconTwo}), url(${ShiftIconOne})`}
            backgroundPosition="right top, right top, right center, left center, left bottom, right bottom, left center"
            backgroundRepeat="no-repeat"
            marginTop={{ md: '170px', xl: '200px' }}
          >
            {Stub.UniqueClassesList.map((item, index) => (
              <ClassCard
                key={item.id}
                item={item}
                index={index}
                animate={inViewport && enterCount === 1 ? animationDirection[index % 2] : 'none'}
              />
            ))}
          </SimpleGrid>
          <Button
            variant="primary"
            size="primary"
            width={{ base: '90%', md: '477px' }}
            height="56px"
            _focus={{ outline: '0 !important' }}
            fontWeight="700"
            fontSize="16px"
            margin={{ base: '39px auto 0', xl: '10px auto 0' }}
            animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            cursor="pointer"
            onClick={onClick}
            alignSelf="center"
            display="flex"
            whiteSpace="pre-line"
          >
            {path === '/grandopening'
              ? `Secure your 2 weeks${isMobileView ? '\n' : ' '}of free classes`
              : `LOCK IN YOUR LOWEST${isMobileView ? '\n' : ' '}MEMBERSHIP RATE!`}
          </Button>
        </InnerContainer>
      </Container>
    </OuterContainer>
  );
};

export default UniqueClasses;
