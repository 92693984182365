import { Box, chakra, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';
import { BackGroundTwo, DownArrow, ThreeForFreeBgMob, YogaJointLogoWithTextSmall } from 'assets';
import { WinnerImages } from 'components';
import { primaryTheme } from 'theming';
import { AnimationFrame, Stub, useNavBarDimensions } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      base: 'linear-gradient(180deg, #181c43 0%, rgba(35, 41, 96, 0) 20%)',
      md: `url(${BackGroundTwo})`,
    },
    backgroundSize: { base: 'cover', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    paddingTop: { base: '120px', md: 'unset' },
    background: { base: `url(${ThreeForFreeBgMob})`, md: 'none' },
    gap: { lg: '40px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: { base: 'column', lg: 'row' },
  },
});

const LeftContainer = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    flexDirection: 'column',
    gap: { base: '8px', md: '20px' },
    width: { base: '100%', lg: '50%' },
  },
});

const RightContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '58px',
    width: { xl: '50%' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '35px', xl: '59px' },
    fontWeight: '500',
    lineHeight: { base: '38px', xl: '76px' },
    color: primaryTheme.colors.brand.white,
    textAlign: { base: 'center', lg: 'unset' },
    letterSpacing: '-1px',
    whiteSpace: 'pre-line',
  },
});

const SubTitle = chakra(Text, {
  baseStyle: {
    fontSize: { sm: '14px', smA: '16px', xl: '25px' },
    fontWeight: '500',
    lineHeight: { base: '21px', xl: '31px' },
    color: primaryTheme.colors.brand.white,
    textAlign: { base: 'center', lg: 'unset' },
    maxWidth: { lg: '445px' },
  },
});

const ButtonText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '16px', xl: '20px' },
    fontWeight: '500',
    lineHeight: { base: '20px', xl: '29px' },
    color: primaryTheme.colors.brand.amethyst,
    maxWidth: { lg: '400px' },
    textAlign: { base: 'center', md: 'unset' },
  },
});

type HeroSectionProps = {
  title: string;
  subTitle: string;
  image: string;
  buttonText?: string;
  isRegistered?: boolean;
  onClick?: () => void;
};

const HeroSection = ({ title, subTitle, image, buttonText, isRegistered, onClick }: HeroSectionProps) => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');

  return (
    <>
      {!isMobileView && (
        <Box margin="120px 0 0 80px">
          <YogaJointLogoWithTextSmall />
        </Box>
      )}
      <OuterContainer paddingLeft={!isMobileView ? margin : ''} paddingRight={!isMobileView ? margin : ''}>
        <Container
          paddingTop={{ base: isRegistered ? '160px' : '10px', md: 'unset' }}
          paddingLeft={isMobileView ? margin : ''}
          paddingRight={isMobileView ? margin : ''}
        >
          {!isRegistered && isMobileView && (
            <Box margin="67px 0 15px 0">
              <YogaJointLogoWithTextSmall />
            </Box>
          )}

          <LeftContainer>
            <Title
              animation={AnimationFrame.slideRight}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />

            <SubTitle
              animation={AnimationFrame.slideLeft}
              dangerouslySetInnerHTML={{
                __html: subTitle,
              }}
            />
            {isMobileView && (
              <Box position="relative">
                {Stub.winnerImages.map((item) => (
                  <Box zIndex={2} position="absolute" top={item.top} left={item.left} animation={item.animation} key={item.index}>
                    <WinnerImages
                      image={item.image}
                      width={isMobileView ? item.widthMob : item.width}
                      height={isMobileView ? item.heightMob : item.height}
                      isSmall={item.isSmall}
                    />
                  </Box>
                ))}
                <Image animation={AnimationFrame.fadeOut} src={image} objectFit="contain" width="80%" margin="auto" />
              </Box>
            )}
            <Box
              onClick={onClick}
              display="flex"
              alignItems="center"
              marginTop={{ base: '5px', md: '35px' }}
              gap="19px"
              cursor="pointer"
            >
              <Flex
                flexDirection={{ base: 'column-reverse', md: 'row' }}
                alignItems={{ base: 'center', md: 'unset' }}
                gap={{ base: '8px', md: '14px' }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={{ base: '43px', lg: '55px' }}
                  height={{ base: '43px', lg: '55px' }}
                  padding="10px"
                  borderRadius="50%"
                  background={primaryTheme.colors.brand.amethyst}
                  animation={AnimationFrame.blink}
                >
                  <DownArrow />
                </Box>
                <ButtonText>{buttonText}</ButtonText>
              </Flex>
            </Box>
          </LeftContainer>
          {!isMobileView && (
            <RightContainer>
              <Box position="relative">
                {Stub.winnerImages.map((item) => (
                  <Box zIndex={2} position="absolute" top={item.top} left={item.left} animation={item.animation} key={item.index}>
                    <WinnerImages image={item.image} width={item.width} height={item.height} isSmall={item.isSmall} />
                  </Box>
                ))}
                <Image
                  animation={AnimationFrame.fadeOut}
                  src={image}
                  objectFit="contain"
                  maxWidth={{ md: '500px', xl: '670px' }}
                />
              </Box>
            </RightContainer>
          )}
        </Container>
      </OuterContainer>
    </>
  );
};

export default HeroSection;
