import { Box, chakra } from '@chakra-ui/react';
import { BackGroundTwo, ShiftIconOne, ShiftIconSix, ShiftIconTwo } from 'assets';
import { YjAppSpec } from 'components';
import { Stub } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    paddingTop: { md: '100px', xl: '400px' },
    marginTop: { base: '95px', lg: 'unset' },
    background: {
      md: `url(${BackGroundTwo})`,
    },
    backgroundSize: { base: 'cover', md: '100% 30%' },
    backgroundRepeat: 'repeat-y !important',
  },
});

const Container = chakra(Box, {
  baseStyle: {
    background: {
      md: `
    linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${ShiftIconOne}), url(${ShiftIconSix}),url(${ShiftIconTwo}), url(${ShiftIconOne})`,
    },
    backgroundPosition: {
      md: '45% 50%, 0% 0% , 0% 0%, 60% 30% !important',
      '3xlB': '40% 50%, 0% 0% , 0% 0%, 60% 30% !important',
    },
    backgroundRepeat: 'no-repeat !important',
  },
});

type AppSectionProps = {
  onClick: () => void;
  path?: string;
};
const AppSection = ({ onClick, path }: AppSectionProps) => {
  return (
    <Container>
      <OuterContainer>
        {Stub.AppSpecList.map((item) => (
          <YjAppSpec
            path={path}
            index={item.index}
            title={item.title}
            description={item.description}
            image={item.image}
            onClick={onClick}
          />
        ))}
      </OuterContainer>
    </Container>
  );
};

export default AppSection;
