import { Box, Button, chakra, Flex, Grid, Text, useMediaQuery } from '@chakra-ui/react';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';
import { useRef } from 'react';

import { useInViewport } from 'react-in-viewport';
import { SinglePackage } from 'components';

const OuterContainer = chakra(Box, {
  baseStyle: {
    maxWidth: { xl: '1254px' },
    margin: { xl: 'auto' },
  },
});
const DescriptionContainer = chakra(Flex, {
  baseStyle: {
    margin: { base: '20px 0 ', lg: '0 0 42px 0' },
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '18px', lg: '20px' },
    fontWeight: { base: '400', lg: '300' },
    lineHeight: { base: '25px', lg: '29px' },
    color: primaryTheme.colors.brand.white,
    maxWidth: '487px',
    whiteSpace: 'pre-line',
    span: {
      fontSize: { base: '20px', xl: '20px' },
      fontWeight: '600',
      lineHeight: { base: '26px', xl: '29px' },
      margin: { md: '24px 0' },
      color: primaryTheme.colors.brand.amethyst,
    },
  },
});

type PackageCard = {
  index: string;
  image: string;
  title: string;
  price: string;
};

type PackagesProps = {
  description: string;
  card: PackageCard[];
  background: string;
  color: string;
  onClick: () => void;
};

const Packages = ({ description, card, background, color, onClick }: PackagesProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isMobileView] = useMediaQuery('(max-width: 770px)');
  const { inViewport, enterCount } = useInViewport(ref);
  return (
    <OuterContainer opacity={enterCount >= 1 ? 1 : 0} ref={ref}>
      <DescriptionContainer>
        <Description
          animation={inViewport || enterCount ? AnimationFrame.slideDown : 'none'}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        {!isMobileView && (
          <Button
            variant="primary"
            size="primary"
            width={{ base: '90%', md: '288px' }}
            height="56px"
            _focus={{ outline: '0 !important' }}
            fontWeight="700"
            fontSize="14px"
            animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            cursor="pointer"
            onClick={onClick}
            alignSelf="center"
            display="flex"
          >
            CLICK HERE IF YOU DIDN’T WIN
          </Button>
        )}
      </DescriptionContainer>

      <Grid
        templateRows={{ base: 'repeat(1fr)' }}
        templateColumns={{ base: 'repeat(1fr)', lg: 'repeat(3, 1fr)' }}
        gap={{ base: '12px', xl: '37px' }}
      >
        {card.map((singleCard) => {
          return (
            <SinglePackage
              key={singleCard.index}
              title={singleCard.title}
              price={singleCard.price}
              image={singleCard.image}
              background={background}
              color={color}
            />
          );
        })}
      </Grid>
    </OuterContainer>
  );
};

export default Packages;
