import { Box, chakra, Flex, useMediaQuery, Text, Divider, Image } from '@chakra-ui/react';
import {
  AppScreens,
  CoralSpringRegisterHero,
  CoralSpringRegisterHeroMob,
  PlayStoreIcon,
  AppStoreIcon,
  YogaJointLogoWithTextSmall,
  ShiftIconOne,
  ShiftIconTwo,
  ShiftIconSix,
  ShiftIconFive,
} from 'assets';
import { AppStoreButton } from 'components';
import { AnimationFrame, useNavBarDimensions } from 'utils';
import { primaryTheme } from 'theming';
import { isAndroid, isIOS } from 'react-device-detect';
import { useEffect, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      lg: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url(${ShiftIconFive}), url(${ShiftIconTwo})`,
      xl: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url(${ShiftIconFive}), url(${ShiftIconSix}), url(${ShiftIconOne}) ,url(${ShiftIconFive}), url(${ShiftIconOne}), url(${ShiftIconOne})`,
    },
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: {
      lg: '80% 90%, 100% 103% !important',
      xl: '0% 100%, 80% 60%, 20% 90%, 120% 70%, 70% 90%, -20% 130%, 130% 140% !important',
      '3xlB': '0% 100%, 75% 80%, 20% 100%, 120% 80%, 60% 100%, -5% 130%, 110% 140% !important',
    },
  },
});

const Container = chakra(Box, {
  baseStyle: {
    background: {
      base: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${CoralSpringRegisterHeroMob})`,
      lg: `url(${CoralSpringRegisterHero})`,
    },
    backgroundSize: {
      sm: '100% 80%',
      smAA: '100% 108%',
      '3xlB': '100% 90%',
    },
    backgroundRepeat: 'no-repeat !important',
  },
});

const InnerContainer = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    paddingTop: { base: '30px', lg: 'unset' },
    gap: { lg: '40px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: 'column',
    paddingBottom: { xl: '280px' },
  },
});
const ImageContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: { base: 'column', lg: 'row' },
    position: 'relative',
    width: ' 100%',
    gap: { base: '20px', lg: 'unset' },
    alignItems: 'center',
    textAlign: { base: 'center', lg: 'unset' },
    justifyContent: { lg: 'space-between' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '34px', lg: '58px' },
    fontWeight: '500',
    lineHeight: { base: '37px', lg: '64px' },
    color: primaryTheme.colors.brand.white,
    marginTop: '34px',
    maxWidth: { lg: '432px' },
  },
});

const SubTitle = chakra(Text, {
  baseStyle: {
    fontSize: { base: '16px', lg: '34px' },
    fontWeight: '400',
    lineHeight: { base: '23px', lg: '50px' },
    color: primaryTheme.colors.brand.white,
    textTransform: 'capitalize',
    maxWidth: { base: '330px', md: 'unset' },
    marginTop: { base: '24px', lg: 'unset' },
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '16px', lg: '20px' },
    fontWeight: { base: '500', lg: '400' },
    lineHeight: { base: '22px', lg: '28px' },
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    backgroundClip: 'text',
    margin: { base: '16px 0 41px', lg: '71px 0 20px' },
    maxWidth: { lg: '363px' },
  },
});

const HighlightText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '15px', lg: '18px' },
    fontWeight: '500',
    lineHeight: { base: '21px', lg: '25px' },
    color: primaryTheme.colors.brand.lemonYellow,
    maxWidth: { lg: '450px' },
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    background: primaryTheme.colors.gradients.preSaleGradient,
    width: '112px',
    height: '7px',
    border: '0.5px solid transparent',
    opacity: '1',
    bgRepeat: 'no-repeat',
    margin: { base: '0 auto 5px', lg: '0 0 5px' },
  },
});

const CustomDividerGrey = chakra(Divider, {
  baseStyle: {
    background: primaryTheme.colors.brand.white,
    width: { base: '100%', md: '98%' },
    height: '0px',
    border: '0.5px solid transparent',
    opacity: '0.3',
    bgRepeat: 'no-repeat',
    marginLeft: { lg: '20px' },
  },
});

const HeroSection = () => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  useEffect(() => {
    if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.yogajoint.mobile';
    } else if (isIOS) {
      window.location.href = 'https://apps.apple.com/us/app/shift-by-yoga-joint/id1553410096';
    }
  }, []);
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);

  return (
    <OuterContainer>
      <Container
        paddingLeft={!isMobileView ? margin : ''}
        paddingRight={!isMobileView ? margin : ''}
        paddingBottom={{ base: '130px', xl: 'unset' }}
      >
        {!isMobileView && (
          <Box padding="40px 0 244px 0">
            <YogaJointLogoWithTextSmall />
          </Box>
        )}
        <InnerContainer paddingLeft={isMobileView ? margin : ''} paddingRight={isMobileView ? margin : ''}>
          {isMobileView && (
            <Box margin="0 0 48px 0">
              <YogaJointLogoWithTextSmall width="158px" height="39px" />
            </Box>
          )}
          <Flex
            justifyContent="space-between"
            flexDirection={{ base: 'column', lg: 'row' }}
            textAlign={{ base: 'center', lg: 'unset' }}
          >
            <Box animation={AnimationFrame.slideRight} width={{ lg: '90%' }}>
              <CustomDivider />
              <Title width={{ lg: '90%', xl: 'unset' }}>
                Thank You
                {!isMobileView && <br />} For Your Interest In Our Coral Springs Studio!
              </Title>
            </Box>
            <Box width={{ lg: '80%' }} animation={AnimationFrame.slideLeft}>
              <SubTitle>
                To celebrate the grand opening of our Coral Springs studio on June 29th, we are offering{' '}
                <strong>two weeks of unlimited FREE classes!</strong>
              </SubTitle>
              <Description>
                Free classes will be available the first two weeks upon opening, from <br />
                <strong>JUNE 29TH - JULY 14TH.</strong>
              </Description>
            </Box>
          </Flex>
          <ImageContainer ref={ref}>
            <CustomDividerGrey position="absolute" top="24%" />
            <Image
              zIndex="2"
              src={AppScreens}
              maxWidth={{ lg: '350px', xl: '422px' }}
              height={{ base: '160px', lg: '400px', xl: '503px' }}
              width={{ lg: '90%' }}
              animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            />
            <Box width={{ lg: '47%' }} animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
              <Box>
                <HighlightText>
                  Download the Yoga Joint app to take advantage and secure your <strong>2 weeks of FREE CLASSES!</strong>
                  <Flex gap="20px" marginTop="28px" justifyContent={{ base: 'center', lg: 'flex-start' }}>
                    <AppStoreButton
                      title="App Store"
                      subTitle="Download on the"
                      icon={<AppStoreIcon />}
                      route="https://apps.apple.com/us/app/shift-by-yoga-joint/id1553410096"
                    />
                    <AppStoreButton
                      route="https://play.google.com/store/apps/details?id=com.yogajoint.mobile"
                      title="Google Play"
                      subTitle="Get it on"
                      icon={<PlayStoreIcon />}
                    />
                  </Flex>
                </HighlightText>
              </Box>
            </Box>
            <CustomDividerGrey position="absolute" bottom={{ base: '-10%', lg: '24%' }} />
          </ImageContainer>
        </InnerContainer>
      </Container>
    </OuterContainer>
  );
};

export default HeroSection;
